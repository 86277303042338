
import Mobiledesign from '../image/mobiledesign.webp'
import GooglePlay from '../image/googleplay.png'
import Applestore from '../image/applestore.png'
import ScrollReveal from "./ScrollReveal";
function Download() {
  return (
    <div className="md:h-screen md:max-h-[730px]  md:flex border-b-2  bg-white justify-center items-center">
      <div className=" w-full md:w-1/2 md:pl-10 pl-4 mt-8 md:mt-0">
      <div className="text-center md:text-start  px-4">
        <h1 className="text-3xl md:text-4xl pb-2 mt-4 font-semibold text-black md:pb-4 font-PPAgrandir">
          Download Now
        </h1>
      </div>
        
        <div className="w-full md:w-full flex justify-start p-4">
          <div className="text-start space-y-6">
            <h2 className="text-black text-base  md:ml-4 md:text-lg lg:text-xl w-[80%]">
              Download MAYA app now to experience seamless access to our services!
            </h2>
            <div className=''>
              <h2 className="text-black text-xl font-semibold md:text-2xl font-PPAgrandir">For Customer</h2>
              <div className="flex justify-start md:mt-4 ml-4">
              <div className="md:flex md:space-x-4 mt-4">
               <img src={GooglePlay} alt="googleplay" className="h-12  lg:h-16 xl:h-20 mb-4"/>
               <img src={Applestore} alt="applestore" className=" h-12 lg:h-16 xl:h-20"/>
              </div>
              </div>
            </div>
            <div className=''>
              <h2 className="text-black text-xl font-semibold md:text-2xl font-PPAgrandir">For Partner's</h2>
              <div className="flex justify-start md:mt-4 ml-4">
              <div className="md:flex md:space-x-4 mt-4">
               <img src={GooglePlay} alt="googleplay" className="h-12  lg:h-16 xl:h-20 mb-4"/>
               <img src={Applestore} alt="applestore" className=" h-12 lg:h-16 xl:h-20"/>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="md:w-1/2">
        <div className=" w-full flex justify-start p-4">
          <ScrollReveal>
          <img
            src={Mobiledesign}
            alt="app" id='primaryImage'
            className="w-full h-auto max-w-lg object-cover rounded-3xl transform hover:scale-105 transition duration-300"
           loading="lazy"/>
          </ScrollReveal>
        </div>
      </div>
    </div>
  );
}

export default Download;
